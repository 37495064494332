import * as React from "react"
import logoImage from "./al__biddy_logo.png";
import styled from "styled-components";

const LogoImage = styled.img`
  @media only screen and (max-width: 768px) {
    width:75px;
  }
`
const LogoWrapper = styled.div``

const Logo = () => (
  <LogoWrapper>
    <title>AlBiddy</title>
    <LogoImage src={logoImage} alt='logo' />
  </LogoWrapper>
)

export default Logo
